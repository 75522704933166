<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Modeling and Analysis" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <div
          class="fusion-column-wrapper"
          style="background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
            ><a href="https://ivm13.com/" target="_blank"
              ><img
                src="@/assets/RiskManagement/ivm.png"
                width="500"
                alt=""
                title="lroom"
                class="img-responsive wp-image-12108"/></a
          ></span>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            The
            <a
              class="link text-red-800"
              href="https://ivm13.com/"
              target="_blank"
              >Integrated Violence Model (IVM)</a
            >
            is a resource for predicting the outcome from HD 1.3 reactions. It
            can be used to obtain venting recommendations and safe distances for
            HD 1.3 hazardous substances and articles. It has been validated for
            use with a variety of substances and configurations. It was
            developed by SMS and has been used for the last 10 years in
            industry.
          </p>
          <p>
            SMS also developed the
            <router-link
              title="GoDetect-ESD"
              style="color:#aa222c;"
              to="/test-equipment/godetect"
              >GoDetect<sup>TM</sup></router-link
            >
            software system which is a python based machine-leaning solution to
            automatically determine reactions during sensitivity testing of
            small samples when exposed to friction, impact, and ESD impetuses.
          </p>
          <p>
            SMS is proficient at using
            <router-link
              title="Blast-X Overview"
              style="color:#aa222c;"
              to="/risk-management/modeling-and-analysis/blastx-analysis"
              >BlastX Analysis</router-link
            >
            to estimate the force on structures from an explosive blast. SMS
            also uses finite element solution such as COMSOL to further estimate
            the effects of a blast or deflagration within a room. This data is
            used for facility siting and hazards assessment.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Modeling and Analysis",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS has developed the Integrated Violence Model to analyze and predict pressurization rates and fragment velocities. SMS has developed GoDetect to automatically detect reactions during sensitivity testing of small samples."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
